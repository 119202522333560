export const name = "Ashish Vyas";
export const title = "Senior Frontend Engineer";
export const bio = "I turn ideas into reality with clean, secure, and efficient code.";

export const about = [
    "Back in 2012, I decided to try my hand at creating custom Wordpress themes and tumbled head first into the rabbit hole of coding and web development. Fast-forward to today, and I’ve had the privilege of building software for a fortune 500 corporation and my alma mater.",
    "My main focus these days is building accessible user interfaces for our consumer facing applications at Cisco. I most enjoy building software in the sweet spot where design and engineering meet — things that look good but are also built well under the hood.",
]

export const experience = [
    {
        id: "exp0",
        time: "Dec 2020 - Present",
        company: "Cisco Systems, Inc.",
        link: "https://www.cisco.com/",
        location: "San Jose, CA",
        position: "Software Engineer (Contract)",
        description: "Build and maintain critical components used to construct frontend, across multiple products. Work closely with cross-functional teams, including developers, designers, testers and product managers, to implement and advocate for best practices in web accessibility.",
        tags: ["JavaScript", "React", "MongoDB", "Node.js", "Okta", "Stripe"]
    },
    {
        id: "exp1",
        time: "Jun 2019 - Mar 2020",
        company: "UC Santa Barbara",
        link: "https://www.ucsb.edu/",
        location: "Santa Barbara",
        position: "Full Stack Developer",
        description: "Developed and styled interactive web app that allows students, faculty and staff to file and process petitions.",
        tags: ["JavaScript", "PHP", "Laravel"]
    }
];

export const education = [
    {
        id: "edu0",
        time: "Sep 2018 - Jun 2020",
        company: "UC Santa Barbara",
        link: "https://www.ucsb.edu/",
        location: "Santa Barbara, CA",
        position: "M.S. Electrical and Computer Engineering",
        description: "Advanced knowledge in digital signal processing, machine learning algorithms, and real-time operating systems, applied in research and development settings.",
        tags: ["Embedded Systems", "Image Processing", "Computer Vision", "Machine Learning", "Matrix Analysis", "Signal Compression", "Data Structures and Algorithms"],
    },
    {
        id: "edu1",
        time: "Jun 2013 - Jun 2017",
        company: "Indus University",
        link: "https://iite.indusuni.ac.in/",
        location: "Ahmedabad, India",
        position: "B.Tech. Electrical and Electronics Engineering",
        description: "Hands-on experience in designing and implementing embedded systems, coupled with coursework emphasizing algorithms, data structures, and network protocols.",
        tags: ["Signal Processing", "Microprocessors", "Microcontrollers", "Circuit Theory", "Analog Communication", "Digital Communication", "Control Theory"],
    },
];

export const projects = [
    {
        id: "pro0",
        time: "Dec 2020 - Present",
        company: "Cisco Systems, Inc.",
        link: "https://www.cisco.com/",
        location: "San Jose, CA",
        position: "Software Engineer (Contract)",
        description: "Build and maintain critical components used to construct frontend, across multiple products. Work closely with cross-functional teams, including developers, designers, testers and product managers, to implement and advocate for best practices in web accessibility.",
        tags: ["JavaScript", "React", "MongoDB", "Node.js", "Okta", "Stripe"]
    },
    {
        id: "pro1",
        time: "Jun 2019 - Mar 2020",
        company: "UC Santa Barbara",
        link: "https://www.cisco.com/",
        location: "Santa Barbara",
        position: "Full Stack Developer",
        description: "Developed and styled interactive web app that allows students, faculty and staff to file and process petitions.",
        tags: ["JavaScript", "PHP", "Laravel"]
    }
];