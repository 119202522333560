import './App.scss';
import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { ReactComponent as GithubSVG } from './assets/icons/github.svg';
import { ReactComponent as LinkedInSVG } from './assets/icons/linkedin.svg';
import { ReactComponent as StackOverflowSVG } from './assets/icons/stackoverflow.svg';
import { ReactComponent as EmailSVG } from './assets/icons/email.svg';
import { name, title, about, experience, education, projects } from './content';
import Accordion from './accordion';

function App() {
  const [isActive, setIsActive] = useState(0);

  return (
    <div className="App">
      <div className='Bio'>
        <header>
          <div className='Introduction'>
            <h1>{name}</h1>
            <h2>{title}</h2>
            <p>
              I turn ideas into reality with
              <div className="rotate-word">
                <span>clean code.</span>
                <span>secure code.</span>
                <span>efficient code.</span>
                <span>readable code.</span>
              </div>
            </p>
          </div>
          <nav className='Navbar'>
            <a className={isActive === 0 ? 'active' : ''} href="#about">
              <span className='indicator'></span>
              <span className='text'>About</span>
            </a>
            <a className={isActive === 1 ? 'active' : ''} href='#experience'>
              <span className='indicator'></span>
              <span className='text'>Experience</span>
            </a>
            <a className={isActive === 2 ? 'active' : ''} href='#education'>
              <span className='indicator'></span>
              <span className='text'>Education</span>
            </a>
            <a className={isActive === 3 ? 'active' : ''} href='#projects'>
              <span className='indicator'></span>
              <span className='text'>Projects</span>
            </a>
          </nav>
          <ul aria-label="Social Media" className='Socials'>
            <a title="Github" href="https://github.com/ashish-ucsb" target="_blank" rel="noopener noreferrer" aria-label="Github (opens in a new tab)"><GithubSVG /></a>
            <a title="LinkedIn" href="https://www.linkedin.com/in/ashishvyasxpu/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn (opens in a new tab)"><LinkedInSVG /></a>
            <a title="stack overflow" href="https://stackoverflow.com/users/11554376/ashish-ucsb" target="_blank" rel="noopener noreferrer" aria-label="stack overflow (opens in a new tab)"><StackOverflowSVG /></a>
            <a title="Email" href="mailto:ashish@ucsb.edu" aria-label="Email"><EmailSVG /></a>
          </ul>
        </header>
      </div>
      <div className='Profile'>
        <main>
          <section id='about'>
            <Waypoint
              onEnter={() => setIsActive(0)}

            />
            <div className='title'>About</div>
            <div className='content'>
              {about.map((item) => <p>{item}</p>)}
            </div>
          </section>
          <section id='experience'>
            <Waypoint
              onEnter={() => setIsActive(1)}
              bottomOffset="50%"
            />
            <div className='title'>Experience</div>
            <ol>
              {experience.map((item) =>
                <Accordion
                  time={item.time}
                  title={item.position + ' · ' + item.company}
                  link={item.link}
                  description={item.description}
                  tags={item.tags}
                />)}
              <Accordion
                time=""
                title="View Full Résumé"
                link="/resume"
                description=""
                tags={[]}
              />
            </ol>
          </section>
          <section id='education'>
            <Waypoint
              onEnter={() => setIsActive(2)}
              bottomOffset="50%"
            />
            <div className='title'>Education</div>
            <ol>
              {education.map((item) =>
                <Accordion
                  time={item.time}
                  title={item.position + ' · ' + item.company}
                  link={item.link}
                  description={item.description}
                  tags={item.tags}
                />)}
            </ol>
          </section>
          <section id='projects'>
            <Waypoint
              onEnter={() => setIsActive(3)}
              bottomOffset="50%"
            />
            <div className='title'>Projects</div>
            <ol>
              {projects.map((item) =>
                <Accordion
                  time={item.time}
                  title={item.position + ' · ' + item.company}
                  link={item.link}
                  description={item.description}
                  tags={item.tags}
                />)}
            </ol>
            <Accordion
              time=""
              title="View Full Project Archive"
              link="/archive"
              description=""
              tags={[]}
            />
          </section>
        </main>
        <footer>
          <p>Loosely designed in Figma and coded in Visual Studio Code by yours truly. Built with React and deployed with Vercel.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
