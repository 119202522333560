import './App.scss';
import { ReactComponent as ArrowUpRight } from './assets/icons/arrow-up-right.svg';

function Accordion({ time, title, link, description, tags }) {
    return (
        <div className="Accordion">
            <div className="header">{time}</div>
            <div className="body">
                <span><a href={link} target="_blank" rel="noopener noreferrer">{title} <ArrowUpRight /></a></span>
                <p>{description}</p>
                <ul>
                    {tags.map((tag) => <li>{tag}</li>)}
                </ul>
            </div>
        </div>
    )
}

export default Accordion;